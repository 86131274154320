<script>
  import axios from "axios";
  import MainCard from "@/components/widgets/Cards/MainCard.vue";
  export default {
    components: {
      MainCard,
    },
    data() {
      return {
        guidelines: [
          {
            id: 1,
            title: "Decálogo del monitor en su día a día",
            description:
              "A continuación te presentamos una guía general de funciones del día a día como monitor para continuar sumando a la operación. El objetivo es el crecimiento personal y profesional de todos los que hacemos parte de esta gran compañía.",
            image: "https://cdn.models1a.com/autologin/tips/decalogo-pruebas.jpg",
          },
          {
            id: 2,
            title: "Decálogo del monitor con la modelo nueva",
            description:
              "Toda modelo que ingresa nueva a Models1A es otra oportunidad de continuar cambiando vidas y generar un “ganar-ganar”. Los detalles sobre los que debemos concentrar en esta primera etapa, las acciones a realizar, la configuración del perfil y el “acompañamiento all the time” son factores importantes que determinan el éxito de estos nuevos perfiles. Aquí te presentamos algunos puntos de vital importancia para iniciar este proceso con las modelos nuevas.",
            image: "https://new-web-site-bucket.s3.amazonaws.com/autologin/tips/decalogo-nuevas-modelos.jpg",
          },
        ],
      };
    },
    methods: {
      async getGuidelines() {
        this.guidelines = await axios.get("url");
      },
    },
  };
</script>

<template>
  <div class="tips">
    <div class="guidelineComponent">
      <h2 class="guidelineComponent__title">Manuales</h2>
      <template v-if="guidelines.length">
        <MainCard v-for="guideline in guidelines" :key="guideline.id" class="guidelineComponent__cards">
          <h3 class="guidelineComponent__cardTitle">{{ guideline.title }}</h3>
          <p v-if="guideline.description" class="guidelineComponent__cardDescription">{{ guideline.description }}</p>
          <figure class="guidelineComponent__cardFigure">
            <img :src="guideline.image" :alt="guideline.image" class="guidelineComponent__cardImage" />
          </figure>
        </MainCard>
      </template>
    </div>
  </div>
</template>

<style lang="scss">
  .tips {
    .guidelineComponent {
      @include Flex(column, flex-start, center);
      width: 100%;
      max-width: 820px;
      min-height: 90vh;
      padding: 0 0.1rem;
      text-align: center;
      padding-top: 50px;
      &__title {
        width: 100%;
        margin-bottom: 1rem;
        text-align: left;
        font-size: 32px;
      }
      &__cards {
        max-width: 1440px;

        height: auto;
        text-align: justify;
        overflow: hidden;
        box-shadow: 0 2px 5px #d8d8d8;
        margin-bottom: 15px;
      }
      &__cardTitle {
        font-size: 32px;
      }
      &__cardDescription {
        margin-bottom: 1rem;
      }
      &__cardFigure {
        width: 100%;
        text-align: center;
      }
      &__cardImage {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    @media (max-width: 430px) {
      margin-bottom: 70px;
    }
  }
</style>
